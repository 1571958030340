/*
Template Name: Osahan Eat - Online Food Ordering Website HTML Template
Author: Askbootstrap
Author URI: https://themeforest.net/user/askbootstrap
Version: 1.0
*/
/*
-- Body
-- Nav
-- login/Register
-- Homepage Search Block
-- Homepage
-- Customize Bootstrap
-- Listing
-- Detail
-- Checkout Page
-- My Account
-- Track Order Page
-- Footer
-- Mobile Media
*/
/* Body */
body {
   background: #f3f7f8;
   font-size: 13px;
   font-weight: 500;
   font-family: "Poppins", sans-serif;
}
button,
a {
   outline: none !important;
   color: #262134;
   text-decoration: none !important;
}
p {
   font-size: 13px;
   color: #7a7e8a;
}
img {
   image-rendering: auto;
}

.vertical-center {
   display: flex;
   flex-direction: column;
   justify-content: center;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
   color: #000000;
}
.datepicker-daily{
   border: 1px solid #9d9ba3;
   border-radius: 4px;
   margin-bottom: 20px;
   text-align: center;
   display: flex;
   justify-self: end;
   background: transparent;
}
.datepicker-custom{
   border: 1px solid #9d9ba3;
   border-radius: 4px;
   margin-bottom: 20px;
   text-align: center;
   width: 80px;
   display: flex;
   justify-self: end;
}
.fz4 {
   font-size: 0.25rem;
}

.fz6 {
   font-size: 0.375rem;
}

.fz8 {
   font-size: 0.5rem;
}

.fz10 {
   font-size: 0.625rem;
}

.fz11 {
   font-size: 0.6875rem;
}

.fz12 {
   font-size: 0.75rem;
}

.fz14 {
   font-size: 0.875rem;
}

.fz16 {
   font-size: 1rem;
}

.fz18 {
   font-size: 1.125rem;
}

.fz20 {
   font-size: 1.25rem;
}

.fz22 {
   font-size: 1.375rem;
}

.fz24 {
   font-size: 1.5rem;
}
.adaptive-fz24 {
   font-size: 1.5rem;
}

.fz26 {
   font-size: 1.625rem;
}

.fz28 {
   font-size: 1.75rem;
}

.fz30 {
   font-size: 1.875rem;
}
.fz32 {
   font-size: 2rem;
}
.fz34 {
   font-size: 2.125rem;
}
.fz36 {
   font-size: 2.25rem;
}
.w-10 {
   width: 10%;
}
.w-20 {
   width: 20%;
}
.w-30 {
   width: 30%;
}
.w-40 {
   width: 40%;
}
.w-60 {
   width: 60%;
}
.w-70 {
   width: 70%;
}
.w-80 {
   width: 80%;
}
.w-90 {
   width: 90%;
}
.w-max-content {
   width: max-content;
}
.stat-grid {
   display: grid;
   padding: 0 30px;
   margin-top: 20px;
   gap: 10px;
   grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
}
@media (max-width: 1700px) {
   .stat-grid {
      grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
   }
}
@media (max-width: 1439px) {
   .stat-grid {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
   }
}
.bg-green{
   background-color: #18b58b !important;
}
.bg-blue{
   background-color: #144c72 !important;
}
.user-image{
   width: 36px;
   height: 36px;
   border-radius: 100%;
   margin-right: 10px;
}
.user-image-lg{
   width: 50px;
   height: 50px;
   border-radius: 100%;
}
.user-image-large{
   width: 280px;
   height: 280px;
   margin-left: 50px;
   object-fit: cover;
   border-radius: 100%;
}
.table{
   padding: 20px;
}
.table-head{
   display: grid;
   gap: 20px;
   grid-template-columns: 0.1fr 1fr 1fr 1fr 1fr;
}
.table-box{
   display: grid;
   gap: 20px;
   grid-template-columns: 0.1fr 1fr 1fr 1fr 1fr;
}
.table-box-active{
   background: #dc1515;
}
/* Nav */
.osahan-nav {
   background: #fff none repeat scroll 0 0 !important;
   padding: 0;
   z-index: 9;
   height: 75px;
}
.osahan-nav .nav-link {
   font-weight: 600;
   padding: 28px 0px !important;
   margin: 0 0 0 31px;
}
.dropdown-cart .dropdown-menu {
   padding-top: 0px !important;
}
.nav-osahan-pic {
   width: 32px;
   height: 32px;
   position: relative;
   margin: -8px 5px -6px 0;
   vertical-align: text-top;
}
.dropdown-cart-top {
   border-top: 2px solid #ff3008;
   min-width: 340px;
}
.dropdown-cart-top-header {
   min-height: 107px;
}
.dropdown-cart-top-header .img-fluid {
   border: 1px solid #dcdcdc;
   border-radius: 3px;
   float: left;
   height: 59px;
   padding: 3px;
   width: 59px;
}
.dropdown-cart-top-header p.text-secondary {
   font-size: 11px;
   line-height: 24px;
   font-weight: 600;
}
.dropdown-cart-top-header h6 {
   font-size: 14px;
}
.food-item {
   border: 1px solid;
   border-radius: 2px;
   display: inline-block;
   font-size: 6px;
   height: 13px;
   line-height: 12px;
   text-align: center;
   width: 13px;
}
.dropdown-cart-top-body .food-item {
   float: left;
   margin: 4px 10px 0 0;
   padding-left: 1px;
}
/* login/Register */
:root {
   --input-padding-x: 1.5rem;
   --input-padding-y: 0.75rem;
}
.login,
.image {
   min-height: 100vh;
}
.bg-image {
   /* background-image: url('/img/login-bg.png'); */
   background-size: cover;
   background-position: center;
}
.login-heading {
   font-weight: 300;
}
.btn-login {
   font-size: 0.9rem;
   letter-spacing: 0.05rem;
   padding: 0.75rem 1rem;
   border-radius: 2rem;
}
.form-label-group {
   position: relative;
   margin-bottom: 1rem;
}
.form-label-group > input,
.form-label-group > label {
   padding: 15px 0;
   border-radius: 0px;
   height: 51px;
   background: transparent !important;
   border: none;
   border-bottom: 1px solid #ced4da;
   box-shadow: none !important;
}
.form-label-group > label {
   position: absolute;
   top: 0;
   left: 0;
   display: block;
   width: 100%;
   margin-bottom: 0;
   /* Override default `<label>` margin */
   line-height: 1.5;
   color: #495057;
   cursor: text;
   /* Match the input under the label */
   border: 1px solid transparent;
   border-radius: 0.25rem;
   transition: all 0.1s ease-in-out;
}
.form-label-group input::-webkit-input-placeholder {
   color: transparent;
}
.form-label-group input:-ms-input-placeholder {
   color: transparent;
}
.form-label-group input::-ms-input-placeholder {
   color: transparent;
}
.form-label-group input::-moz-placeholder {
   color: transparent;
}
.form-label-group input::placeholder {
   color: transparent;
}
.form-label-group input:not(:placeholder-shown) {
   padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
   padding-bottom: calc(var(--input-padding-y) / 3);
}
.form-label-group input:not(:placeholder-shown) ~ label {
   padding-top: calc(var(--input-padding-y) / 3);
   padding-bottom: calc(var(--input-padding-y) / 3);
   font-size: 12px;
   color: #777;
}
.not-allowed {
   pointer-events: auto! important;
   cursor: not-allowed! important;
}
.msg-count{
   color: #fff !important;
   font-size: 10px;
   padding: 2px 4px;
   min-width: 12px;
   min-height: 12px;
   display: flex;
   justify-content: center;
   align-content: center;
   border-radius: 4px;
   background: #E7372C;
}
.msg-active{
   color: #E7372C !important;
   background: #fff;
}
.btn-google {
   color: white;
   background-color: #ea4335;
}
.btn-facebook {
   color: white;
   background-color: #3b5998;
}
/* Homepage Search Block */
.homepage-search-block {
   background-color: #f4f4f5;
   padding: 20px 0;
}
.homepage-search-form .form-control {
   font-size: 15px;
   height: 50px;
   padding-right: 106px;
}
.homepage-search-form .form-group {
   position: relative;
}
.homepage-search-form .locate-me {
   background: #ececec none repeat scroll 0 0;
   color: #666;
   font-size: 12px;
   padding: 7px 10px;
   border-radius: 3px;
   position: absolute;
   right: 14px;
   top: 9px;
}
.homepage-search-form .locate-me:hover {
   background: #666 none repeat scroll 0 0;
   color: #ffffff;
}
.homepage-search-form .btn {
   font-size: 15px;
   font-weight: 600;
   height: 50px;
   padding: 13px 5px;
}
.homepage-search-form .location-dropdown i {
   left: 18px;
   position: absolute;
   top: 20px;
   z-index: 9;
}
.homepage-search-form .select2-selection {
   border-color: #ced4da !important;
   border-radius: 0.25rem !important;
   height: 50px !important;
   outline: none !important;
}
.homepage-search-form .select2-selection__rendered {
   font-size: 15px;
   line-height: 47px !important;
   padding: 0 28px 0 31px !important;
}
.homepage-search-form .select2-selection__arrow {
   right: 9px !important;
   top: 13px !important;
}
.select2-dropdown {
   margin: -2px 0 0 0;
   border: 1px solid #ced4da;
   border-radius: 3px;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
   border: 1px solid #ced4da;
}
.select2-search__field {
   outline: none !important;
   border: 1px solid #ced4da;
}
/* .owl-carousel {
	position: relative;
}
.owl-theme .owl-nav [class*="owl-"] {
	position: absolute;
	bottom: calc(50% - 20px);
	background: transparent none repeat scroll 0 0 !important;
	color: #5C3EBA !important;
	height: 40px;
	line-height: 40px;
	margin: 0 !important;
	text-align: center;
	text-transform: uppercase;
	width: 40px;
	display: flex !important;
	align-items: center;
	justify-content: center;
}
.owl-theme .owl-nav [class*="owl-"] i {
	font-size: 18px !important;
	font-weight:normal !important;
}
.owl-theme .owl-nav .owl-prev {
	margin-left: -30px !important;
}
.owl-theme .owl-nav .owl-next {
	right:0px;
	margin-right: -30px !important;
}
.owl-carousel-four .item {
	padding: 6px 7px;
} */

/* Test */

.owl-carousel {
   position: relative;
   margin-top: 20px;
}
.owl-theme .owl-nav [class*="owl-"] {
   position: absolute;
   top: -30px;
   right: 30px;
   background: #fff none repeat scroll 0 0 !important;
   border-radius: 5px !important;
   /* box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2); */
   color: #5c3eba !important;
   height: 30px;
   line-height: 40px;
   margin: 0 !important;
   text-align: center;
   text-transform: uppercase;
   width: 30px;
   display: flex !important;
   align-items: center;
   justify-content: center;
}
.owl-theme .owl-nav [class*="owl-"] i {
   font-size: 14px !important;
   font-weight: normal !important;
}
.owl-theme .owl-nav .owl-prev {
   right: 35px;
   margin-left: -30px !important;
}
.owl-theme .owl-nav .owl-next {
   /* right:0px; */
   margin-right: -30px !important;
}
/* Homepage */
.owl-carousel-four .item {
   padding: 6px 7px;
}
.owl-theme .owl-nav .disabled {
   color: #d4d3d6 !important;
}

/* Test */

.osahan-category-item {
   /* background: #fff; */
   margin: 5px 2px;
   border-radius: 3px;
   overflow: hidden;
   text-align: center;
   padding: 10px;
   color: #262134;
   /* box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important; */
   /* border: 1px solid #fff; */
   width: 140px !important;
}
.osahan-category-item h6 {
   margin: 0px;
   font-size: 13px;
   color: #ff3008;
}
.osahan-category-item p {
   margin: 0px;
   font-size: 12px;
}
.osahan-category-item img {
   width: 100%;
   height: 84px;
   object-fit: cover;
   border-radius: 4px;
   background: transparent;
   animation: 0s ease 0s 1 normal none running none;
   display: inline-block !important;
   /* box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important; */
   /* margin: 2px 0 8px 0; */
}
.line {
   background-color: #5e5e5e;
   border-radius: 12px;
   display: inline-table;
   height: 4px;
   margin: 0 0 51px;
   width: 50px;
}
/* Customize Bootstrap */
.small,
small {
   font-size: 85%;
   font-weight: 600;
}
.select2,
.select2 span {
   width: 100% !important;
}
.select2-container--default
   .select2-selection--single
   .select2-selection__arrow
   b {
   left: 96%;
}
.select2-container--default .select2-selection--single {
   background-color: #fff;
   border: 1px solid #ced4da;
   border-radius: 3px;
}
.modal-footer .btn {
   font-size: 14px;
   padding: 12px 16px;
}
.btn-success,
.badge-success {
   background-color: #3ecf8e !important;
   border-color: #3ecf8e !important;
}
.btn-outline-success {
   color: #3ecf8e !important;
   border-color: #3ecf8e !important;
}
.btn-outline-success:hover {
   color: #ffffff !important;
   border-color: #3ecf8e !important;
   background-color: #3ecf8e !important;
}
.text-success {
   color: #3ecf8e !important;
}
.border-success {
   border-color: #3ecf8e !important;
}
.btn-danger,
.badge-danger {
   background-color: #f32129 !important;
   border-color: #f32129 !important;
}
.btn-outline-danger {
   color: #f32129 !important;
   border-color: #f32129 !important;
}
.btn-outline-danger:hover {
   color: #ffffff !important;
   border-color: #f32129 !important;
   background-color: #f32129 !important;
}
.text-danger {
   color: #f32129 !important;
}
.border-danger {
   border-color: #f32129 !important;
}
.btn-primary,
.badge-danger {
   background-color: #ff3008 !important;
   border-color: #ff3008 !important;
}
.badge-grey {
   background-color: #9d9ba3 !important;
   border-color: #9d9ba3 !important;
}
.btn-outline-primary {
   color: #ff3008 !important;
   border-color: #ff3008 !important;
}
.btn-outline-primary:hover,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
   color: #ffffff !important;
   border-color: #ff3008 !important;
   background-color: #ff3008 !important;
}
.text-primary {
   color: #ff3008 !important;
}
.border-primary {
   border-color: #ff3008 !important;
}
.btn-lg {
   font-size: 15px;
   font-weight: bold;
   padding: 14px 35px;
   letter-spacing: 1px;
}
.modal-content {
   border: none;
   border-radius: 2px;
}
.btn {
   border-radius: 2px;
}
.form-control {
   font-size: 13px;
   border-radius: 2px;
   min-height: 38px;
}
.card {
   border-radius: 2px;
}
.rounded {
   border-radius: 2px !important;
}
.input-group-text {
   border-radius: 2px;
}
.custom-checkbox .custom-control-label::before {
   border-radius: 2px;
}
.nav-pills .nav-link {
   border-radius: 2px;
}
.alert,
.badge,
.dropdown-menu {
   border-radius: 2px;
}
.input-group-sm > .custom-select,
.input-group-sm > .form-control,
.input-group-sm > .input-group-append > .btn,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-prepend > .input-group-text {
   border-radius: 2px;
}
.bg-dark {
   background-color: #000 !important;
}
.pb-5,
.py-5 {
   padding-bottom: 4rem !important;
}
.pt-5,
.py-5 {
   padding-top: 4rem !important;
}
.dropdown-menu {
   font-size: 13px;
}
.dropdown-item {
   border-radius: 0 !important;
   padding: 7px 18px;
   color: rgba(0, 0, 0, 0.5);
   font-weight: 500;
}
.dropdown-item:focus {
   background-color: #ff3008;
   color: #fff;
}
/* Listing */
.list-cate-page {
   margin: -5px 0px 0px 0px;
}
.filters-card-body {
   padding: 18px 0 0 0;
}
.filters-search {
   position: relative;
}
.filters-search i {
   position: absolute;
   left: 12px;
   top: 13px;
}
.filters-search .form-control {
   padding-left: 34px;
}
.custom-checkbox label.custom-control-label,
.custom-radio label.custom-control-label {
   padding-top: 2px;
   cursor: pointer;
}
.filters-card-header a {
   font-weight: 500;
   width: 100%;
   display: block;
}
.filters-card-header a i {
   margin: 2px -2px 0 0;
}
.position-absolute {
   z-index: 1;
}
iframe.position-absolute {
   z-index: 0;
}
.list-card .count-number {
   margin: 4px 0 0 0;
   display: inline-block;
}
.list-card a {
   color: inherit;
}
.list-card a:hover {
   color: #0091bc;
}
.list-card .star {
   right: 8px;
   bottom: 8px;
}
.list-card .star .badge {
   font-size: 11px;
   padding: 5px 5px;
   box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.list-card .member-plan {
   left: 19px;
   bottom: 22%;
}
.list-card .member-plan .badge {
   font-size: 11px;
   padding: 5px 5px;
   box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.badge-pill {
   padding-right: 0.6em;
   padding-left: 0.6em;
   border-radius: 10rem;
}
.list-card .list-card-image {
   position: relative;
}
.list-card .favourite-heart {
   right: 8px;
   top: 8px;
   box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.list-card .favourite-heart {
   background: #fff;
   width: 23px;
   height: 23px;
   display: inline-block;
   text-align: center;
   font-size: 15px;
   line-height: 24px;
   border-radius: 50px;
}
/* detail */
.count-number .btn {
   padding: 2px 5px;
   font-size: 12px;
   border-radius: 0px;
}
.restaurant-detailed-header-left {
   text-shadow: 0px 0px 10px #000;
}
.count-number-input {
   width: 24px;
   text-align: center;
   margin: 0 -4px;
   background: #6c757d;
   border: none;
   color: #fff;
   height: 24px;
   border-radius: 0px;
   vertical-align: bottom;
}
.generator-bg {
   /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ff3008+1,fe792b+100 */
   background: #ff3008; /* Old browsers */
   background: -moz-linear-gradient(
      -45deg,
      #ff3008 1%,
      #fe792b 100%
   ); /* FF3.6-15 */
   background: -webkit-linear-gradient(
      -45deg,
      #ff3008 1%,
      #fe792b 100%
   ); /* Chrome10-25,Safari5.1-6 */
   background: linear-gradient(
      135deg,
      #ff3008 1%,
      #fe792b 100%
   ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff3008', endColorstr='#fe792b',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.restaurant-detailed-banner {
   position: relative;
}
.restaurant-detailed-banner .img-fluid.cover {
   height: 424px;
   object-fit: cover;
}
.restaurant-detailed-header {
   bottom: 0;
   left: 0;
   padding: 65px 0 17px;
   position: absolute;
   right: 0;
   background: -moz-linear-gradient(
      top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.83) 100%
   );
   background: -webkit-linear-gradient(
      top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.83) 100%
   );
   background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.83) 100%
   );
   filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#d4000000', GradientType=0);
}
.homepage-great-deals-carousel .item img {
   border-radius: 2px;
}
.restaurant-detailed-earn-pts .img-fluid.float-left {
   height: 61px;
}
.card-icon-overlap {
   overflow: hidden;
   position: relative;
}
.red-generator-bg {
   background: #ff3371;
   background: -moz-linear-gradient(-45deg, #ff3371 1%, #fe6753 100%);
   background: -webkit-linear-gradient(-45deg, #ff3371 1%, #fe6753 100%);
   background: linear-gradient(135deg, #ff3371 1%, #fe6753 100%);
   filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#ff3371', endColorstr='#fe6753', GradientType=1);
}
.border-btn {
   border: 1px solid #dee0e6;
   border-radius: 2px;
   display: inline-block;
   font-size: 14px;
   font-weight: 500;
   padding: 8px 15px;
   margin: 0 0 10px 0;
}
.restaurant-detailed-ratings .generator-bg {
   border-radius: 4px;
   display: inline-block;
   font-size: 12px;
   height: 20px;
   margin: 0 4px 0 0;
   padding: 3px 6px;
}
.explore-outlets-search .form-control {
   border-radius: 2px !important;
   font-size: 15px;
   height: 50px;
   padding: 5px 20px;
}
.explore-outlets-search .btn {
   color: #777777;
   font-size: 15px;
   padding: 13px 17px;
   position: absolute;
   z-index: 9;
   right: 0;
   text-decoration: none;
}
.mall-category-item {
   border: 1px solid rgba(0, 0, 0, 0.1);
   border-radius: 3px;
   margin: 0 6px;
   overflow: hidden;
   text-align: center;
   padding: 0 0 7px 0;
}
.mall-category-item img {
   height: 89px;
   object-fit: cover;
   width: 100%;
}
.mall-category-item h6 {
   font-size: 14px;
   font-weight: 500;
   margin: 0;
   padding: 10px 10px 3px 10px;
}
.mall-category-item .btn {
   padding: 2px 8px;
   font-size: 12px;
   right: 7px;
   top: 7px;
}
.mall-category-item small {
   background: #ececec;
   color: #f32129;
   padding: 1px 6px;
   border-radius: 2px;
   font-size: 10px;
}
.mall-category-item:hover small {
   background: #f32129;
   color: #ffffff;
}
.address-map {
   border-radius: 2px;
   overflow: hidden;
}
.new-arrivals-card .card-img .new-arrivals {
   bottom: 11px;
   left: 12px;
   position: absolute;
}
.new-arrivals {
   background: #000 none repeat scroll 0 0;
   border-radius: 2px;
   color: #fff;
   padding: 1px 13px;
   text-transform: uppercase;
}
.new-arrivals-card .card-img {
   position: relative;
}
.total-like-user {
   border: 2px solid #fff;
   height: 34px;
   box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
   width: 34px;
}
.total-like-user-main a {
   display: inline-block;
   margin: 0 -17px 0 0;
}
.total-like {
   border: 1px solid;
   border-radius: 50px;
   display: inline-block;
   font-weight: 500;
   height: 34px;
   line-height: 33px;
   padding: 0 13px;
   vertical-align: top;
}
.restaurant-detailed-ratings-and-reviews hr {
   margin: 0 -24px;
}
.star-rating {
   cursor: pointer;
}
.graph-star-rating-header .star-rating {
   font-size: 17px;
}
.progress {
   background: #f2f4f8 none repeat scroll 0 0;
   border-radius: 0;
   height: 30px;
}
.rating-list {
   display: inline-flex;
   margin-bottom: 15px;
   width: 100%;
}
.rating-list-left {
   height: 16px;
   line-height: 29px;
   width: 10%;
}
.rating-list-center {
   width: 80%;
}
.rating-list-right {
   line-height: 29px;
   text-align: right;
   width: 10%;
}
.restaurant-slider-pics {
   bottom: 0;
   font-size: 12px;
   left: 0;
   z-index: 999;
   padding: 0 10px;
}
.restaurant-slider-view-all {
   bottom: 15px;
   right: 15px;
   z-index: 999;
}
.offer-dedicated-nav .nav-link.active,
.offer-dedicated-nav .nav-link:hover,
.offer-dedicated-nav .nav-link:focus {
   border-color: #3868fb;
   color: #3868fb;
}
.offer-dedicated-nav .nav-link {
   border-bottom: 2px solid #fff;
   color: #000000;
   padding: 16px 0;
   font-weight: 600;
}
.offer-dedicated-nav .nav-item {
   margin: 0 37px 0 0;
}
.restaurant-detailed-action-btn {
   margin-top: 12px;
}
.restaurant-detailed-header-right .btn-success {
   border-radius: 3px;
   height: 45px;
   margin: -18px 0 18px;
   min-width: 130px;
   padding: 7px;
}
.text-black {
   color: #000000 !important;
}
.icon-overlap {
   bottom: -23px;
   font-size: 74px;
   opacity: 0.23;
   position: absolute;
   right: -32px;
}
.menu-list img {
   width: 41px;
   height: 41px;
   object-fit: cover;
}
.restaurant-detailed-header-left img {
   width: 88px;
   height: 88px;
   border-radius: 3px;
   object-fit: cover;
   box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
/* Checkout Page */
.addresses-item a.btn.btn-sm {
   min-width: 157px;
}
.osahan-cart-item-profile img {
   width: 40px;
   height: 40px;
   border: 3px solid #fff;
}
.osahan-cart-item-profile p {
   font-size: 10px;
}
.osahan-payment .nav-link {
   padding: 18px 20px;
   border: none;
   font-weight: 600;
   color: #535665;
}
.osahan-payment .nav-link i {
   width: 28px;
   height: 28px;
   background: #535665;
   display: inline-block;
   text-align: center;
   line-height: 29px;
   font-size: 15px;
   border-radius: 50px;
   margin: 0 7px 0 0px;
   color: #fff;
}
.osahan-payment .nav-link.active {
   background: #f3f7f8;
   color: #282c3f !important;
}
.osahan-payment .nav-link.active i {
   background: #282c3f !important;
}
.osahan-payment .tab-content {
   background: #f3f7f8;
   padding: 20px;
}
.osahan-card i {
   font-size: 35px;
   vertical-align: middle;
   color: #6c757d;
}
.osahan-card {
   margin: 0 0 0 7px;
}
.osahan-payment label {
   font-size: 12px;
   margin: 0 0 3px 0;
   font-weight: 600;
}
/* My Account */
.payments-item img.mr-3 {
   width: 47px;
}
.order-list .btn {
   border-radius: 2px;
   min-width: 121px;
   font-size: 13px;
   padding: 7px 0 7px 0;
}
.osahan-account-page-left .nav-link {
   padding: 18px 20px;
   border: none;
   font-weight: 600;
   color: #535665;
}
.osahan-account-page-left .nav-link i {
   width: 28px;
   height: 28px;
   background: #535665;
   display: inline-block;
   text-align: center;
   line-height: 29px;
   font-size: 15px;
   border-radius: 50px;
   margin: 0 7px 0 0px;
   color: #fff;
}
.osahan-account-page-left .nav-link.active {
   background: #f3f7f8;
   color: #282c3f !important;
}
.osahan-account-page-left .nav-link.active i {
   background: #282c3f !important;
}
.osahan-user-media img {
   width: 90px;
}
.card offer-card h5.card-title {
   border: 2px dotted #000;
}
.card.offer-card h5 {
   border: 1px dotted #daceb7;
   display: inline-table;
   color: #17a2b8;
   margin: 0 0 19px 0;
   font-size: 15px;
   padding: 6px 10px 6px 6px;
   border-radius: 2px;
   background: #fffae6;
   position: relative;
}
.card.offer-card h5 img {
   height: 22px;
   object-fit: cover;
   width: 22px;
   margin: 0 8px 0 0;
   border-radius: 2px;
}
.card.offer-card h5:after {
   border-left: 4px solid transparent;
   border-right: 4px solid transparent;
   border-bottom: 4px solid #daceb7;
   content: "";
   left: 30px;
   position: absolute;
   bottom: 0;
}
.card.offer-card h5:before {
   border-left: 4px solid transparent;
   border-right: 4px solid transparent;
   border-top: 4px solid #daceb7;
   content: "";
   left: 30px;
   position: absolute;
   top: 0;
}
.card.offer-card .card-btn {
   font-size: 13px;
   color: #ff3008;
   vertical-align: top !important;
   border: 0px !important;
}
.card.offer-card .card-btn:hover {
   color: #0056b3;
}
.payments-item .media {
   align-items: center;
}
.payments-item .media img {
   margin: 0 40px 0 11px !important;
}
.reviews-members .media .mr-3 {
   width: 56px;
   height: 56px;
   object-fit: cover;
}
.order-list img.mr-4 {
   width: 70px;
   height: 70px;
   object-fit: cover;
   box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
   border-radius: 2px;
}
.osahan-cart-item p.text-gray.float-right {
   margin: 3px 0 0 0;
   font-size: 12px;
}
.osahan-cart-item .food-item {
   vertical-align: bottom;
}
/* Track Order Page */

.osahan-track-order-page {
   min-height: 676px;
}
.osahan-point {
   z-index: 1;
   width: 50%;
   border-radius: 50%;
   padding-bottom: 50%;
   pointer-events: none;
   background-color: rgba(225, 48, 8, 0.2);
   border: 1px solid #ff3008;
}
/* Footer */
.footer {
   background: #edf1f4;
}
.footer h6 {
   font-size: 14px;
   text-transform: uppercase;
}
.footer ul {
   margin: 0;
   padding: 0;
}
.footer ul li {
   line-height: 26px;
   list-style: outside none none;
}
.footer ul li a {
   color: #6c8293;
}
.search-links a {
   color: #666;
   font-size: 12px;
   line-height: 23px;
   margin: 0 2px;
}
.search-links {
   font-size: 11px;
}
footer {
   background-color: #f0f4f7;
}
.navbar-nav .offer-btn {
   padding: 0 !important;
   display: flex;
   align-items: center;
}
.offer-btn .btn {
   background: linear-gradient(45deg, #d92662 0%, #e23744 100%);
   border-radius: 0.25rem;
}
.heart-icon {
   display: flex;
   align-items: center;
   justify-content: center;
   height: 100%;
}
.list-card-sales-image {
   width: 130px;
   height: 140px;
   min-width: 130px;
   max-width: 130px;
}
.list-card.d-flex .list-card-sales-image .item-img {
   height: 140px;
   object-fit: cover;
}
.list-card.d-flex .list-card-image .item-img {
   height: 140px;
   object-fit: cover;
}
.list-card.d-flex .list-card-body span.float-right.text-black-50 {
   display: none;
}
.list-card .list-card-sales-image {
   position: relative;
}
.list-unstyled {
   padding-left: 0;
   list-style: none;
}
.rating-stars .feather-star.star_active {
   background: #f5cd25;
}
.rating-stars .feather-star {
   font-size: 10px;
   background: #000000;
   color: #fff;
   padding: 2px;
   text-shadow: 0px 0px 1px #d2b128;
   border-radius: 5px;
}
.border-radius-21 {
   border-radius: 21px !important;
}
.h-100vh {
   height: 100vh;
}
.input-underline {
   outline: 0;
   border-width: 0 0 2px;
   border-color: #ced4da;
}
.input-underline:focus {
   border-color: #5c3eba;
}
.background-white {
   background-color: #fff;
}
.sidebar {
   position: relative;
   display: flex;
   flex: 0 0 256px;
   flex-direction: column;
   order: -1;
   width: 256px;
   padding: 0;
   box-shadow: none;
   transition: box-shadow 0.3s 0.15s, transform 0.3s, margin-left 0.3s,
      margin-right 0.3s, width 0.3s, z-index 0s ease 0.3s,
      -webkit-transform 0.3s;
}
.sidebar-fixed {
   position: fixed;
   top: 0;
   bottom: 0;
   z-index: 1030;
}
.sidebar-brand {
   display: flex;
   flex: 0 0 56px;
   padding-top: 15px;
   align-items: flex-start;
   background: #fff;
   /*box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);*/
   border-bottom: 1px solid gray;
   z-index: 1;
}

.sidebar-nav {
   position: relative;
   display: flex;
   flex: 1 1;
   flex-direction: column;
   padding: 0;
   margin-bottom: 0;
   overflow-x: hidden;
   overflow-y: auto;
   list-style: none;
   background: #fff;
}
.sidebar-nav-item {
   width: inherit;
   border-radius: 6px;
   color: #000 !important;
}
.sidebar-nav-item:hover {
   transition: 0.3s;
   background: #E7372C;
   color: #fcfcfc !important;
}
.sidebar-nav-item:hover .msg-hover {
   background: #fff;
   transition: 0.3s;
   color: #E7372C !important;
}
.sidebar-nav-active {
   transition: 0.3s;
   background: #E7372C;
   border-radius: 6px;
   color: #fcfcfc !important;
}
.sidebar-nav-children {
   background-color: #fff;
   border-radius: 6px;
   list-style: none;
   padding-bottom: 5px;
   padding-top: 5px;
   padding-inline-start: 0;
   height: inherit;
   width: inherit;
   margin-bottom: 5px;
   margin-top: 0px;
   color: #000 !important;
}
.sidebar-nav-children-item {
   width: 100%;
   color: #000 !important;
   padding: 10px 10px 10px 10px;
   margin-left: 5px;
   margin-right: 5px;
}
.sidebar-nav-children-item:hover{
   transition: 0.3s;
   background: #E7372C;
   border-radius: 6px;
   color: #fff !important;
}
.children-active{
   background: #E7372C;
   border-radius: 6px;
   color: #fff !important;
}
.header {
   display: flex;
   flex: 0 0 56px;
   align-items: center;
   padding-left: 256px;
}
.cursor {
   cursor: pointer;
}
.sidebar-backdrop {
   position: fixed;
   top: 0;
   left: 0;
   z-index: 1030;
   width: 100vw;
   height: 100vh;
   background-color: #000015;
   transition: 0.3s;
}
.wrapper {
   display: flex;
   flex: 1 1;
   flex-direction: column;
   min-width: 0;
   min-height: 100vh;
   margin-left: 256px;
}
.wrapper {
   transition: margin 0.3s;
}
.border-radius-15 {
   border-radius: 15px;
}
.border-radius-10 {
   border-radius: 10px;
}
.border-radius-5 {
   border-radius: 5px;
}
.border-radius-3 {
   border-radius: 3px;
}
.box-shadow {
   box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
}
.btn-purple,
.btn-outline-purple:hover {
   background: #5c3eba;
   border-radius: 5px;
   color: #fff;
   transition: 0.3s;
}
.btn-purple:hover,
.btn-outline-purple {
   background: #fff;
   color: #5c3eba;
   border: 1px solid #5c3eba;
   border-radius: 5px;
   transition: 0.3s;
}
.btn-outline-purple:focus,
.btn-purple:focus {
   box-shadow: 0 0 0 0.2rem rgb(92 62 186 / 50%);
}
.btn-red,
.btn-outline-red:hover {
   background: #e63950;
   border-radius: 5px;
   color: #fff;
   transition: 0.3s;
}
.btn-red:hover,
.btn-red-purple {
   background: #fff;
   color: #e63950;
   border: 1px solid #e63950;
   border-radius: 5px;
   transition: 0.3s;
}
.btn-outline-red:focus,
.btn-red:focus {
   box-shadow: 0 0 0 0.2rem rgb(230 57 80 / 50%);
}
.p-btn {
   padding: 0.8rem !important;
}
.btn-outline-orange {
   background: #fff;
   color: #ffa91a;
   border: 1px solid #ffa91a;
   border-radius: 5px;
   transition: 0.3s;
}
.btn-outline-orange:hover {
   background: #ffa91a;
   border-radius: 5px;
   color: #fff;
   transition: 0.3s;
}
.btn-outline-orange {
   background: #fff;
   color: #ffa91a;
   border: 1px solid #ffa91a;
   border-radius: 5px;
   transition: 0.3s;
}
.btn-outline-orange:hover {
   background: #ffa91a;
   border-radius: 5px;
   color: #fff;
   transition: 0.3s;
}
.bg-blue {
   background: #0badee;
   border-radius: 5px;
   color: #fff;
}
.bg-red {
   background: #e63950;
   border-radius: 5px;
   color: #fff;
}
.bg-yellow {
   background: #ffd10c;
   border-radius: 5px;
   color: #fff;
}
.bg-orange {
   background: #ffa91a;
   border-radius: 5px;
   color: #fff;
}
.bg-pink {
   background: #f574f8;
   border-radius: 5px;
   color: #fff;
}
.bg-purple {
   background: #5c3eba;
   border-radius: 5px;
   color: #fff;
}
.bg-green {
   background: #0bc940;
   border-radius: 5px;
   color: #fff;
}
.bg-grey-dark {
   background: #9d9ba3;
   color: #fff;
}
.alert-blue {
   background-color: #dbf3fd !important;
}
.alert-red {
   background-color: #fbe2e5 !important;
}
.alert-orange {
   background-color: #fff2dd !important;
}
.alert-yellow {
   background-color: #fff8db !important;
}
.alert-pink {
   background-color: #feeafe !important;
}
.alert-purple {
   background-color: #e7e2f5 !important;
}
.alert-green {
   background-color: #dbf7e3 !important;
}
.bg-black {
   background-color: #262134;
   color: #fff;
}
.bg-black:hover {
   background-color: #262134;
   color: #fff;
}
.border-bottom-dashed {
   border-bottom: 1px dashed #7d7a85;
}
.border-top-dashed {
   border-top: 1px dashed #7d7a85;
}
/* .btn-blue:hover {
background: #fff;
color: #189ED3;
border: 1px solid #189ED3;
border-radius: 5px;
transition: 0.3s;
}  */
.border {
   border: 1px solid #d4d3d6;
   /* color:lightcyan */
}
.outline-none {
   outline: none;
}
::-webkit-calendar-picker-indicator {
   filter: invert(0.8);
}
.postion-absolute {
   position: absolute;
}
.z-index {
   z-index: 1;
}
.basket {
   position: absolute;
   width: 100%;
   /* top: 100%;
width: 100%;
transition:top 1s linear; */
   height: calc(100vh - 75px);
   /* height: 90vh; */
   z-index: 21;
   background-color: #fff;
   /* position: fixed; */
}
.mobile-header {
   display: none;
}
.font-weight-500 {
   font-weight: 500 !important;
}
.font-weight-350 {
   font-weight: 350 !important;
}
.font-weight-600 {
   font-weight: 600 !important;
}
.font-weight-750 {
   font-weight: 750 !important;
}
.disabled-link {
   pointer-events: none;
}
.table > :not(:last-child) > :last-child > * {
   border-bottom-color: #d4d3d6;
}
.table-bordered thead th {
   border-bottom-width: 1px;
}
.vertical-middle {
   vertical-align: middle !important	;
}

.border-dashed {
   border: 1px dashed #d4d3d6;
}

.text-dark {
   color: #262134 !important;
}
#loading-bg {
   width: 100%;
   height: 100%;
   background: transparent;
   display: block;
   position: absolute;
   z-index: 1070;
   cursor: not-allowed;
}
.loading-logo {
   position: absolute;
   left: calc(50% - 45px);
   top: 40%;
}
.loading {
   position: absolute;
   left: calc(50% - 35px);
   top: 50%;
   z-index: 2100;
   width: 55px;
   height: 55px;
   border-radius: 50%;
   -webkit-box-sizing: border-box;
   box-sizing: border-box;
   border: 3px solid transparent;
}
.loading .effect-1,
.loading .effect-2 {
   position: absolute;
   width: 100%;
   height: 100%;
   border: 3px solid transparent;
   border-left: 3px solid #5c3eba;
   border-radius: 50%;
   -webkit-box-sizing: border-box;
   box-sizing: border-box;
}

.loading .effect-1 {
   animation: rotate 1s ease infinite;
}
.loading .effect-2 {
   animation: rotateOpacity 1s ease infinite 0.1s;
}
.loading .effect-3 {
   position: absolute;
   width: 100%;
   height: 100%;
   border: 3px solid transparent;
   border-left: 3px solid #5c3eba;
   -webkit-animation: rotateOpacity 1s ease infinite 0.2s;
   animation: rotateOpacity 1s ease infinite 0.2s;
   border-radius: 50%;
   -webkit-box-sizing: border-box;
   box-sizing: border-box;
}
.loading .effects {
   transition: all 0.3s ease;
}

.spinner-border {
   display: inline-block;
   width: 55px;
   height: 55px;
   vertical-align: -0.125em;
   border: 0.25em solid currentColor;
   border-right-color: transparent;
   border-radius: 50%;
   animation: 0.75s linear infinite spinner-border;
}
.text-purple {
   color: #5c3eba !important;
}
.btn-postion {
   position: absolute;
   bottom: 20px;
   right: 30px;
}
.profile-card {
   box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.16);
   border-radius: 10px;
   background-color: #fff;
   position: relative;
   cursor: pointer;
}
.admin-card {
   min-height: 50vh;
   box-shadow: 0px 4px 12px rgb(0 0 0 / 12%);
   border-radius: 5px;
   background-color: #fff;
   position: relative;
}
.input-control {
   outline: none;
   color: black;
   border: 1px solid #dee2e6;
   width: 100%;
   border-radius: 3px;
   padding: 0.5rem;
}
.input-disabled {
   background-color: #dee2e6;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
}
.image-block {
   border: 1px dashed #d4d3d6;
   cursor: pointer;
   padding: 0.5rem 1.5rem;
   display: flex;
   align-items: center;
   justify-content: center;
   text-align: center;
}
.image-input {
   z-index: 3;
   position: absolute;
   top: 0;
   opacity: 0;
   outline: none;
   color: #7d7a85;
   border-radius: 3px;
   cursor: pointer;
}
.bg-grey {
   background: #f4f4f5;
}

.btn-rounded {
   border-radius: 5px;
}
.product-add-button {
   cursor: pointer;
   width: 128px;
   height: 128px;
   padding: 30px 15px;
   background: #ffffff;
   border: 1px dashed #d4d3d6;
   box-sizing: border-box;
   border-radius: 5px;
   margin-right: 8px;
}
.products-list {
   display: flex;
   flex-wrap: wrap;
}
.products-list-item {
   margin-right: 8px;
   display: flex;
   flex-direction: column;
   position: relative;
}
.product-list-icon {
   position: absolute;
   right: 10px;
   top: 3px;
}
.product-selected {
   color: #5c3eba;
   border: 2px solid #5c3eba;
   border-radius: 7px;
}
.product-not-selected {
   color: #9d9ba3;
}

.menu-add-product {
   background: #f4f4f5;
   border-radius: 7px;
   padding: 1rem;
}

.menu-product-list {
   display: flex;
   flex-wrap: wrap;
}

.menu-product-img {
   object-fit: cover;
   border-radius: 7px;
}
.territory-header {
   cursor: pointer;
   padding: 0.5rem;
   margin-right: 0.5rem;
}
.territory-header-disabled {
   background-color: rgb(246, 246, 246);
   border-top-left-radius: 5px;
   border-top-right-radius: 5px;
   color: rgb(92, 62, 186);
}
.territory-header-active {
   background-color: rgb(255, 255, 255);
   border-top-left-radius: 5px;
   border-top-right-radius: 5px;
   border: 1px solid rgb(222, 226, 230);
}
.btn-status {
   border-radius: 5px;
   color: #fff !important;
   margin: auto !important;
   padding: 0.5rem !important;
   display: flex;
   justify-content: center;
   align-items: center;
}
.btn-status-success {
   background-color: #0badee;
}
.btn-status-danger {
   background-color: #e63950;
}
.order-status {
   background-color: #0badee;
   border-radius: 20px;
   font-size: 0.75rem;
   padding: 0 1rem;
   color: #fff;
   display: flex;
   align-items: center;
}
.input-search-icon {
   position: absolute;
   top: 30%;
   left: 8px;
}
.search-icon{
   background-image: url("/src/assets/gen004.svg");
   background-repeat: no-repeat;
   background-color: #fcfcfc;
   background-size: 18px;
   background-position: 5px center;
}
.input-search {
   height: 38px;
   border: 1px solid rgb(212, 211, 214);
   outline: 0;
   padding-right: 1.5rem;
   padding-left: 2.5rem;
   padding-bottom: 0.5rem;
   padding-top: 0.5rem;
   border-radius: 5px;
}
td {
   text-align: center !important;
   vertical-align: middle !important;
}
.table-btn {
   white-space: nowrap;
   border-radius: 15px;
   padding-left: 0.5rem !important;
   padding-right: 0.5rem !important;
   padding-bottom: 0.25rem !important;
   padding-top: 0.25rem !important;
   color: #fff;
   font-size: 0.75rem;
}
.btn-table {
   border-radius: 5px;
   color: #fff;
   padding: 0.25rem 0.5rem;
   margin-right: 0.5rem;
   border: 1px solid #dee2e6 !important;
}
.btn-grey {
   background-color: #d4d3d6;
   color: #fff;
   transition: 0.3s;
}

/* .btn-grey:hover {
	background-color: #fff;
	color: rgb(212, 211, 214);
	transition: 0.3s;
} */
.rounded-text {
   background-color: #fff;
   color: #262134;
   padding: 0.438rem 0.5rem;
   border-radius: 15px;
   box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
   display: flex;
   justify-content: center;
   align-items: center;
   /* white-space: nowrap; */
}
.rounded-text-lg {
   background-color: #fff;
   color: #262134;
   padding: 0.438rem 0.8rem;
   border-radius: 15px;
   box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
   display: flex;
   justify-content: center;
   align-items: center;
   /* white-space: nowrap; */
}
.color-grey {
   /* color: #7D7A85; */
   color: #9d9ba3;
}
.bg-grey-light {
   background: #f4f4f5;
}
.bg-purple {
   background-color: #5c3eba;
   color: #fff;
}
.in-basket {
   border-bottom: 5px solid #5c3eba;
}
.w-fit-content {
   width: fit-content;
}
.input-checkbox {
   /* -webkit-box-shadow: 0px 0px 0px 1px rgba(255,0,0,1);
	-moz-box-shadow: 0px 0px 0px 1px rgba(255,0,0,1);
	box-shadow: 0px 0px 0px 1px rgba(255,0,0,1);
	outline: 1px solid #189ED3; */
   border: 1px solid #f00;
}
.price-text {
   font-size: 0.75rem;
}

.admin-field {
   color: #7d7a85;
   margin-bottom: 1rem !important;
   display: flex;
   align-items: center;
   justify-content: flex-end;
   flex-direction: column;
}
.admin-label {
   display: flex;
   align-items: center;
   justify-content: flex-end;
   width: 60%;
}
.border-none {
   border: none;
}
.admin-select {
   color: #7d7a85;
   outline: none;
   padding: 0.5rem;
   border-radius: 3px;
   width: 100%;
   border: 1px solid #dee2e6 !important;
}
.notification {
   background-color: #e63950;
   border-radius: 50%;
   color: #fff;
   padding: 0.12rem 0.4rem;
   margin: 0 5px;
}
.add-photo {
   height: 128px;
   width: 128px;
   position: relative;
   background-color: #fff;
   /* border: 1px dashed #D4D3D6; */
   box-sizing: border-box;
   /* border-radius: 50%; */
   text-align: center;
   /* width: max-content; */
   /* padding: 2.8rem 1.4rem; */
   margin-bottom: 30px;
   cursor: pointer;
}
.add-photo-block {
   height: 128px;
   width: 128px;
   border: 1px dashed #d4d3d6;
   border-radius: 50%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   cursor: pointer;
}
.add-photo-input {
   height: 128px;
   width: 128px;
   border-radius: 50%;
   z-index: 3;
   position: absolute;
   top: 0;
   left: 0;
   opacity: 0;
   outline: none;
   /* width: 100%;
	padding: 3.2rem 1.4rem;
	height: inherit; */
   cursor: pointer;
}
.add-photo-image {
   height: 128px;
   width: 128px;
   position: absolute;
   border-radius: 50%;
   top: 0;
   left: 0;
   cursor: pointer;
   object-fit: cover;
}
.address-field {
   /* width: 100%; */
   width: min-content;
   margin-bottom: 1rem;
   margin-right: 1rem;
   display: flex;
   flex-direction: column-reverse;
}
.address-input {
   outline: 0;
   border-width: 0 0 2px;
   border-color: #ced4da;
   /* border-radius: 5px; */
   display: block;
   width: 100%;
   padding: 0.5rem 0rem;
   font-size: 1rem;
   color: #262134;
   background-color: #fff;
   background-clip: padding-box;
   transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
   outline: none;
}
.address-input:focus {
   border-color: #5c3eba;
}
.address-label {
   font-size: 10px;
   font-weight: 600;
   color: #9d9ba3;
   width: max-content;
}
.profile-field {
   width: 50%;
   margin-bottom: 1.8rem;
   display: flex;
   flex-direction: column-reverse;
}
.profile-field:focus {
   color: #5c3eba;
}
.profile-input {
   border: 2px solid #f4f4f5;
   border-radius: 10px;
   display: block;
   width: 100%;
   padding: 0.5rem 0.75rem;
   font-size: 1rem;
   line-height: 1.5;
   color: #262134;
   background-color: #f4f4f5;
   background-clip: padding-box;
   transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
   outline: none;
}
.profile-label {
   font-size: 10px;
   font-weight: 600;
   color: #9d9ba3;
}
.profile-input:focus {
   border: 2px solid #5c3eba;
   background-color: #fff;
   color: #5c3eba;
}
.profile-input:focus + .profile-label {
   color: #5c3eba !important;
}
.profile-input-disabled {
   border: 2px solid #f4f4f5;
   background: #f4f4f5;
}
label {
   margin-bottom: 0 !important;
}

/* .profile-label-disabled {
	font-size: 10px;
	font-weight: 600;
	color:#9D9BA3;
} */
.color-purple {
   color: #5c3eba;
}
.color-orange {
   color: orange;
}
.b-r-50 {
   border-radius: 50px !important;
}
.red-circle svg {
   color: #e63950;
   fill: #e63950;
}
.grey-circle svg {
   color: #d4d3d6;
   fill: #d4d3d6;
}
.h-fit-content {
   height: fit-content;
}
.profile-order b {
   margin-right: 1rem;
   font-size: 1.5rem;
}
.brand-border {
   border: 3px solid #ffffff;
   border-radius: 10px;
}
.address-btn {
   padding: 0.75rem;
   background: #ffffff;
   display: flex;
   align-items: center;
   box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.16);
   border-radius: 8px;
   position: relative;
   margin-bottom: 1.25rem;
}
.address-btn-active {
   background: #5c3eba !important;
   color: #fff !important;
}
.address-modal {
   position: absolute;
   z-index: 5;
   top: 10%;
   right: 30%;
   padding: 15px;
   background-color: #fff;
   box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.16);
   border-radius: 10px;
}
.deleteIcon {
   position: absolute;
   right: 10px;
   top: 10px;
   background-color: #f4f4f5;
   border-radius: 5px;
   padding: 5px;
   color: #e63950;
   z-index: 5;
   display: flex;
   cursor: pointer;
}
.deleteIcon:hover {
   transition: 0.3s;
   background-color: #e63950;
}
.deleteIcon:hover svg {
   transition: 0.3s;
   /* fill: #E63950; */
   color: #fff;
}
.heartIcon {
   position: absolute;
   right: 10px;
   top: 10px;
   background-color: #f4f4f5;
   border-radius: 5px;
   padding: 5px;
   color: #e63950;
   z-index: 5;
   display: flex;
   cursor: pointer;
}
.heartIcon:hover svg {
   transition: 0.3s;
   fill: #e63950;
}
.status {
   background: #0bc940;
   border-radius: 20px;
   padding: 4px 11px;
   color: #fff;
   display: flex;
}
.status-position {
   position: absolute;
   right: 10px;
   bottom: 11px;
   z-index: 1;
}
.status-danger {
   background: #e63950;
}
.rounded-text-success {
   background: #d1f6dd;
   color: #2ebd5f;
}
.rounded-text-danger {
   background: #f6d1d1;
   color: #e63950;
}
.brand-image {
   height: 64px;
   width: 64px;
   border-radius: 10px;
   object-fit: cover;
}
.branch-image {
   height: 194px;
   object-fit: cover;
}
.login-input {
   background: #f4f4f5;
   border-radius: 10px;
   outline: none;
   width: 100%;
   box-shadow: none;
   padding: 15px;
   padding-top: 30px;
   border: none;
   font-size: 1.5rem;
}
.message-input {
   background: #f4f4f5;
   border-radius: 10px;
   outline: none;
   width: 50%;
   margin: auto;
   box-shadow: none;
   padding: 15px;
   padding-top: 30px;
   border: none;
   font-size: 1.5rem;
   color: #262134;
   text-align: center;
}
.login-input::placeholder {
   font-size: 1.5rem;
}
.login-label {
   position: absolute;
   left: 15px;
   top: 10px;
   font-size: 13px;
   font-weight: 600;
   color: #5c3eba;
}
.message-label {
   position: absolute;
   left: 39%;
   top: 6px;
   font-size: 13px;
   font-weight: 600;
   color: #5c3eba;
}
.color-primary {
   color: #0badee;
}
.br-30 {
   border-radius: 30px;
}
.br-21 {
   border-radius: 21px;
}
.br-10 {
   border-radius: 10px;
}
.br-5 {
   border-radius: 5px;
}
.br-3 {
   border-radius: 3px;
}
.h-max-content {
   height: max-content;
}
.owl-item:first-of-type {
   display: none;
}
.owl-item {
   width: 140px !important;
   display: flex;
   justify-content: center;
}
.basket-modal-position {
   max-width: 100%;
   width: 50%;
   transform: translate(30%, 40%) !important;
   margin-top: auto;
   cursor: default;
}
.basket-modal-backdrop {
   background: #9d9ba3;
   opacity: 1 !important;
   z-index: 7 !important	;
}
.basket-modal {
   z-index: 20 !important;
   cursor: pointer;
}
.basket-menu {
   width: 95%;
   background-color: #fff;
   padding: 0.5rem !important;
   margin: 0;
   margin-top: 0.25rem;
   margin-right: 1.25rem;
   display: flex;
   justify-content: space-between;
   align-items: center;
   border-bottom: 1px solid #d4d3d6;
   margin: 0.25rem auto;
}

.scroll::-webkit-scrollbar {
   width: 5px;
}
.scroll::-webkit-scrollbar-track {
   background: #f1f1f1;
}
.scroll::-webkit-scrollbar-thumb {
   background: #7d7a85;
   border-radius: 5px;
}
.scroll::-webkit-scrollbar-thumb:hover {
   background: rgb(148, 148, 148);
}
.checkout-input {
   border: 1px solid #f6f6f6;
   border-radius: 10px;
   outline: 0;
   background-color: #f6f6f6;
}
.checkout-payment {
   position: relative;
   border-radius: 10px;
   cursor: pointer;
   padding: 15px 20px;
   margin-right: 1rem;
   border: 1px solid #d4d3d6;
   display: flex;
}
.checkout-payment svg {
   position: absolute;
   top: 5px;
   left: 5px;
   background-color: #5c3eba;
   border-radius: 50%;
   padding: 2px;
}
.checkout-payment-acitve {
   border: 2px solid #5c3eba;
}
.obj-fit-cover {
   object-fit: cover;
}
.obj-fit-contain {
   object-fit: contain;
}
.header-input {
   background: #f4f4f5;
   border-radius: 21px;
   color: #9d9ba3;
   padding: 0.375rem 0.75rem;
   outline: none;
   box-shadow: none;
   border: none;
   min-height: 38px;
   width: 100%;
}
.detail-product {
   width: 340px;
   height: 450px;
   border-top-left-radius: 5px;
   border-top-right-radius: 5px;
   margin-right: 30px;
   /* border-radius: 5px 5px 0px 0px; */
}
.detail-product-image {
   border-top-left-radius: 5px;
   border-top-right-radius: 5px;
   object-fit: fill;
   width: 338px;
   height: 270px;
}
.sticky {
   position: sticky;
   padding: 1rem 0;
   display: flex;
   align-items: center;
   /* flex-wrap: wrap; */
   overflow-x: auto;
   white-space: nowrap;
   background-color: #fbfbfb;
   top: 75px;
   z-index: 30;
}
.br-50 {
   border-radius: 50%;
}
.overflow-x-auto {
   overflow-x: auto;
}
.overflow-y-auto {
   overflow-y: auto;
}
.cb1 + label {
   display: inline-block;
   width: 16px;
   height: 16px;
   border: 1px solid #d4d3d6;
   border-radius: 2px;
   cursor: pointer;
   z-index: 2;
}
.cb1:checked + label {
   color: #5c3eba;
   border: 1px solid #5c3eba;
}
.cb1 + label + svg {
   display: none;
}
.cb1:checked + label + svg {
   display: inline-block;
   color: #5c3eba;
}
.cb1:checked,
[type="checkbox"]:not(:checked) {
   position: absolute;
   z-index: 20;
   cursor: pointer;
   /* right: 0; */
}
.cb1 {
   opacity: 0;
}
.bg-light {
   background-color: #f4f4f5 !important;
}
.color-blue {
   color: #000;
   font-weight: 600;
}
.operator-number {
   background-color: #dbf3fd !important;
   display: flex;
   align-items: center;
   border-radius: 5px;
   padding: 2px;
   width: max-content;
   margin: auto;
}
.list-card {
   border-radius: 15px;
}
.list-card:hover {
   transition: 0.15s;
   filter: drop-shadow(0px 8px 18px rgba(0, 0, 0, 0.2));
   transform: translateY(-10px);
}
.detail {
   border-top-left-radius: 15px;
   border-top-right-radius: 15px;
   background-size: contain;
   background-position: top;
   background-repeat: no-repeat;
   transition: all ease 0.4s !important;
   padding-top: 75%;
}
.bg-dark-grey {
   background-color: #d4d3d6;
}
.text-ellipsis {
   white-space: nowrap;
   text-overflow: ellipsis;
   overflow: hidden;
}
.color-red {
   color: #e63950;
}
.main-text {
   margin-left: auto;
   width: calc(100% - 64px * 1.2);
   text-align: start !important;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   color: #5c3eba;
}
.branch-content {
   background-color: #f4f4f5;
   color: #262134 !important;
   border-radius: 15px;
   padding: 0.25rem 0.5rem;
   display: flex;
   justify-content: center;
   align-items: center;
}
.search {
   position: absolute;
   width: 100%;
   z-index: 21;
   background-color: #fff;
   max-height: calc(100vh - 110px);
   top: 70px;
   box-shadow: 0px 4px 12px rgb(0 0 0 / 12%);
   border-radius: 5px;
   overflow-y: auto;
}
.search-width {
   width: 30%;
}
/* @media screen and (max-width: 400.98px) and (min-width: 499.98px) { 
	.heading-left { left: -0.5%; }
} */
@media (max-width: 470.98px) {
   .w-inherit {
      width: inherit;
   }
}
.logo {
   height: 180px;
}

@media (max-width: 992px) {
   .logo {
      height: 150px;
   }
}

@media (max-width: 500px) {
   .logo {
      height: 100px;
   }
}
/* @media(max-width: 300px) {} */
/* Mobile Media */
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
   .justify-content-xs-center {
      justify-content: center !important;
   }
   .m-none {
      display: none;
   }
   .homepage-search-title {
      text-align: center;
   }
   .list-cate-page {
      margin: 22px 0 0 0;
   }
   .d-none-m {
      display: none;
   }
   .row.pt-2.pb-2 {
      padding-bottom: 0px !important;
      padding-top: 0px !important;
   }
   .row.pt-2 {
      padding-top: 0px !important;
   }
   .offer-card-horizontal .col-md-4.col-4.p-4 {
      padding: 0 0 0 15px !important;
   }
   .row.mt-4.pt-2 {
      margin-top: 0px !important;
      padding-top: 0px !important;
   }
   .homepage-add-section {
      padding: 0 13px;
   }
   .breadcrumb-osahan h1 {
      font-size: 34px;
   }
   .breadcrumb-osahan h6 {
      font-size: 15px;
   }
   .breadcrumb-osahan {
      padding-left: 15px;
      padding-right: 15px;
   }
   .reviews-members .media .mr-3 {
      width: 36px;
      height: 36px;
   }
   .total-like {
      font-size: 12px;
   }
   .restaurant-detailed-action-btn.float-right .btn {
      margin: 3px 0px;
      font-size: 13px;
   }
   .login .col-md-9.col-lg-8.mx-auto.pl-5.pr-5 {
      padding: 0 11px !important;
   }
   .login.d-flex.align-items-center.py-5 {
      padding: 0 !important;
   }
   .h5,
   h5 {
      font-size: 16px;
   }
   .homepage-add-section .col-md-3.col-6 {
      padding: 2px 2px;
   }
   .homepage-ad .item {
      padding: 0 2px;
   }
   .osahan-nav {
      padding: 15px 15px;
   }
   .navbar-toggler {
      padding: 5px 8px;
      border-radius: 2px;
   }
   .osahan-nav .nav-link {
      margin: 0px;
      text-align: center;
      padding: 14px 0 !important;
      border-bottom: 1px solid #eef0ef;
   }
   .osahan-nav .dropdown-item {
      text-align: center;
   }
   .osahan-slider {
      padding: 18px 0 0 0px !important;
   }
   .pt-5,
   .py-5 {
      padding-top: 3rem !important;
   }
   .pb-5,
   .py-5 {
      padding-bottom: 3rem !important;
   }
   .homepage-search-block {
      padding: 3rem 0 !important;
   }
   .mobile-none {
      display: none;
   }
   .card {
      margin-bottom: 15px !important;
   }
   p {
      font-size: 12px;
   }
   .restaurant-detailed-header-left img {
      float: none !important;
      margin: 0 0 14px 0 !important;
   }
   .restaurant-detailed-header-left {
      text-align: center;
   }
   .restaurant-detailed-header-right .btn-success {
      margin-top: 18px;
   }
   .restaurant-detailed-header-right.text-right {
      text-align: center !important;
   }
   .restaurant-detailed-action-btn.float-right {
      float: none !important;
      margin: auto;
      text-align: center;
      width: 100% !important;
      display: block;
      padding: 12px 0;
      border-bottom: 1px solid #dedede;
   }
   .offer-dedicated-nav {
      text-align: center;
   }
   .offer-dedicated-nav .nav-item {
      flex: auto;
      border-bottom: 1px solid #ccc;
      padding: 0;
      margin: 0 11px !important;
   }
   .offer-dedicated-nav {
      text-align: center;
   }
   .address-map.float-right.ml-5 {
      float: none !important;
      margin: 0 0 20px 0 !important;
   }
   .address-map.float-right.ml-5 iframe {
      width: 100%;
   }
   .osahan-track-order-detail p.text-gray.mb-5 {
      margin: 1px 0 11px 0 !important;
   }
   .osahan-account-page-left .nav-tabs {
      padding-left: 0px !important;
   }
   .osahan-account-page-left {
      height: auto !important;
      margin: 0 0 15px 0;
   }
   .order-list .float-right {
      float: none !important;
   }
   .row.mb-4 {
      margin-bottom: 0px !important;
   }
   .app {
      margin-bottom: 26px;
   }
   .footer ul li a {
      font-size: 12px;
   }
   .footer h6 {
      font-size: 13px;
      text-transform: capitalize;
   }
   .osahan-payment .col-sm-4.pr-0 {
      padding-right: 15px !important;
   }
   .osahan-payment .col-sm-8.pl-0 {
      padding-left: 15px !important;
      margin: 14px 0 0 0;
   }
   .p-5.osahan-invoice.bg-white.shadow-sm {
      padding: 15px !important;
   }
   .h3,
   h3 {
      font-size: 22px;
   }
   .p-5 {
      padding: 20px !important;
   }
   .osahan-account-page-right {
      padding: 0px !important;
      border: none;
      background: transparent !important;
      box-shadow: none !important;
      margin-top: 15px;
   }
   .tablet-none {
      display: none;
   }
   .mobile-header {
      display: block;
   }
   .list-card .member-plan {
      bottom: 34%;
      /* bottom: calc(40% - 15px); */
   }
   .profile-order b {
      margin-right: 0.25rem;
      font-size: 1rem;
   }
   .address-modal {
      right: 8%;
   }
}
/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
   .justify-content-xs-center {
      justify-content: center !important;
   }
   .homepage-add-section .col-md-3.col-6 {
      padding: 2px 2px;
   }
   .homepage-search-title {
      text-align: center;
   }
   .row.pt-2.pb-2 {
      padding-bottom: 0px !important;
      padding-top: 0px !important;
   }
   .row.pt-2 {
      padding-top: 0px !important;
   }
   .d-none-m {
      display: none;
   }
   .list-cate-page {
      margin: 22px 0 0 0;
   }
   .row.mt-4.pt-2 {
      margin-top: 0px !important;
      padding-top: 0px !important;
   }
   .offer-card-horizontal .col-md-4.col-4.p-4 {
      padding: 0 0 0 15px !important;
   }
   .homepage-add-section {
      padding: 0 13px;
   }
   .homepage-ad .item {
      padding: 0 2px;
   }
   .container {
      max-width: 100%;
   }
   .osahan-nav {
      padding: 15px 15px;
   }
   .navbar-toggler {
      padding: 5px 8px;
      border-radius: 2px;
   }
   .osahan-nav .nav-link {
      margin: 0px;
      text-align: center;
      padding: 14px 0 !important;
      border-bottom: 1px solid #eef0ef;
   }
   .osahan-nav .dropdown-item {
      text-align: center;
   }
   .osahan-slider {
      padding: 18px 0 0 0px !important;
   }
   .pt-5,
   .py-5 {
      padding-top: 3rem !important;
   }
   .pb-5,
   .py-5 {
      padding-bottom: 3rem !important;
   }
   .homepage-search-block {
      padding: 3rem 0 !important;
   }
   .mobile-none {
      display: none;
   }
   .card {
      margin-bottom: 15px !important;
   }
   p {
      font-size: 12px;
   }
   .restaurant-detailed-header-left img {
      float: none !important;
      margin: 0 0 14px 0 !important;
   }
   .restaurant-detailed-header-left {
      text-align: center;
   }
   .restaurant-detailed-header-right .btn-success {
      margin-top: 18px;
   }
   .restaurant-detailed-header-right.text-right {
      text-align: center !important;
   }
   .restaurant-detailed-action-btn.float-right {
      float: none !important;
      margin: auto;
      text-align: center;
      width: 100% !important;
      display: block;
      padding: 12px 0;
      border-bottom: 1px solid #dedede;
   }
   .offer-dedicated-nav {
      text-align: center;
   }
   .offer-dedicated-nav .nav-item {
      flex: auto;
      border-bottom: 1px solid #ccc;
      padding: 0;
      margin: 0 11px !important;
   }
   .offer-dedicated-nav {
      text-align: center;
   }
   .address-map.float-right.ml-5 {
      float: none !important;
      margin: 0 0 20px 0 !important;
   }
   .address-map.float-right.ml-5 iframe {
      width: 100%;
   }
   .osahan-payment .nav-link i {
      display: block;
      margin: 0 auto 10px auto;
   }
   .osahan-payment .nav-link {
      text-align: center;
   }
   .osahan-track-order-detail p.text-gray.mb-5 {
      margin: 1px 0 11px 0 !important;
   }
   .osahan-account-page-left .nav-tabs {
      padding-left: 0px !important;
   }
   .osahan-account-page-left {
      height: auto !important;
      margin: 0 0 15px 0;
   }
   .order-list .float-right {
      float: none !important;
   }
   .row.mb-4 {
      margin-bottom: 0px !important;
   }
   .app {
      margin-bottom: 26px;
   }
   .footer ul li a {
      font-size: 12px;
   }
   .footer h6 {
      font-size: 13px;
      text-transform: capitalize;
   }
   .tablet-none {
      display: none;
   }
   .mobile-header {
      display: block;
   }
   .adaptive-fz24 {
      font-size: 0.7rem;
   }
   .profile-order b {
      font-size: 0.9rem;
      margin-right: 0.5rem;
   }
   .detail-product {
      width: 400px;
      height: 450px;
   }
   .detail-product-image {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      object-fit: fill;
      width: 400px;
      height: 330px;
   }
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
   .container {
      max-width: 100%;
   }
   .osahan-nav {
      padding: 15px 15px;
   }
   .navbar-toggler {
      padding: 5px 8px;
      border-radius: 2px;
   }
   .osahan-nav .nav-link {
      margin: 0px;
      text-align: center;
      padding: 14px 0 !important;
      border-bottom: 1px solid #eef0ef;
   }
   .osahan-nav .dropdown-item {
      text-align: center;
   }
   .osahan-slider {
      padding: 18px 0 0 0px !important;
   }
   .pt-5,
   .py-5 {
      padding-top: 3rem !important;
   }
   .pb-5,
   .py-5 {
      padding-bottom: 3rem !important;
   }
   .homepage-search-block {
      padding: 3rem 0 !important;
   }
   .card {
      margin-bottom: 15px !important;
   }
   p {
      font-size: 12px;
   }
   .restaurant-detailed-header-left img {
      float: none !important;
      margin: 0 0 14px 0 !important;
   }
   .restaurant-detailed-header-left {
      text-align: center;
   }
   .restaurant-detailed-header-right .btn-success {
      margin-top: 18px;
   }
   .restaurant-detailed-header-right.text-right {
      text-align: center !important;
   }
   .restaurant-detailed-action-btn.float-right {
      float: none !important;
      margin: auto;
      text-align: center;
      width: 100% !important;
      display: block;
      padding: 12px 0;
      border-bottom: 1px solid #dedede;
   }
   .offer-dedicated-nav {
      text-align: center;
   }
   .offer-dedicated-nav .nav-item {
      margin: 0 8px !important;
   }
   .offer-dedicated-nav {
      text-align: center;
   }
   .address-map.float-right.ml-5 {
      float: none !important;
      margin: 0 0 20px 0 !important;
   }
   .address-map.float-right.ml-5 iframe {
      width: 100%;
   }
   .osahan-payment .nav-link i {
      display: block;
      margin: 0 auto 10px auto;
   }
   .osahan-payment .nav-link {
      text-align: center;
   }
   .osahan-track-order-detail p.text-gray.mb-5 {
      margin: 1px 0 11px 0 !important;
   }
   .osahan-account-page-left .nav-tabs {
      padding-left: 0px !important;
   }
   .osahan-account-page-left {
      height: auto !important;
      margin: 0 0 15px 0;
   }
   .order-list .float-right {
      float: none !important;
   }
   .row.mb-4 {
      margin-bottom: 0px !important;
   }
   .app {
      margin-bottom: 26px;
   }
   .footer ul li a {
      font-size: 12px;
   }
   .footer h6 {
      font-size: 13px;
      text-transform: capitalize;
   }
   .tablet-none {
      display: none;
   }
   .mobile-header {
      display: block;
   }
   .price-text {
      font-size: 0.625rem;
   }
   .adaptive-fz24 {
      font-size: 1rem;
   }
   .profile-order b {
      font-size: 1rem;
      margin-right: 0.7rem;
   }
   .detail-product {
      width: 360px;
      height: 450px;
   }
   .detail-product-image {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      object-fit: fill;
      width: 358px;
      height: 290px;
   }
}
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
   .container {
      max-width: 100%;
   }
   .tablet-none {
      display: none;
   }
   .mobile-header {
      display: block;
   }
   .price-text {
      font-size: 0.625rem;
   }
   .detail-product {
      width: 290px;
      height: 450px;
   }
   .detail-product-image {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      object-fit: fill;
      width: 288px;
      height: 250px;
   }
}
@media (min-width: 1199.98px) and (max-width: 1300px) {
   .price-text {
      font-size: 0.625rem;
   }
   .detail-product {
      width: 25%;
      height: 450px;
   }
   .detail-product-image {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      object-fit: fill;
      width: 288px;
      height: 250px;
   }
   .search-width {
      width: 40%;
   }
}
@media (min-width: 1300px) and (max-width: 1500px) {
   .price-text {
      font-size: 0.625rem;
   }
   .detail-product {
      width: 25%;
      height: 450px;
   }
   .detail-product-image {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      object-fit: fill;
      width: 100%;
      height: 250px;
   }
   .search-width {
      width: 35%;
   }
}
@media (min-width: 1500px) and (max-width: 1700px) {
   .list-card .member-plan {
      bottom: 22%;
   }
   .container {
      max-width: 1140px;
   }
   .detail-product {
      width: 25%;
      height: 450px;
   }
   .detail-product-image {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      object-fit: fill;
      width: 100%;
      height: 66%;
   }
   .search-width {
      width: 35%;
   }
}
@media (min-width: 1700px) and (max-width: 1980px) {
   .detail-product {
      width: 390px;
      height: 450px;
   }
   .detail-product-image {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      object-fit: fill;
      width: 100%;
      height: 310px;
   }
}
@media (min-width: 1400px) {
   .container {
      max-width: 1320px;
   }
}
@media (min-width: 1600px) {
   .container {
      max-width: 1520px;
   }
   .detail-image {
      height: 270px;
   }
}
/* Extra large devices (large desktops, 1200px and up) */
/* @media (min-width: 1200px) {
	.list-card .member-plan {
		bottom: 25%;
	}
} */

.rowHover {
   border-bottom: rgba(0, 0, 0, 0.1) solid 1px!important;
}

.borderBottom {
   border-bottom: rgba(0, 0, 0, 0.1) solid 1px!important;
}

.rowHover:hover{
   background-color: rgba(231, 55, 44, 0.15)!important;
}


.datepicker-custom-input{
   width: 100%;
   padding: 2px 5px;
   border: 1px solid #c1c1c2;
   border-radius: 3px;
   font-size: 12px;
   height: 100%;
}
.react-datepicker__input-container{
   height: 100%;
}
.custom-react-select{
   width: 200px;
   height: 30px !important;
   font-size: 12px;
}

.wrapper-container{
   width: 100%;
   gap: 20px;
   display: flex;
}

.products-container{
   width: 100%;
}

.masters-container{
   width: 100%;
}
.react-datepicker-wrapper{
   width: 100% !important;
   height: 100% !important;
}

.wrapper-container-full{
   width: 100%;
}

.box-flex{
   display: flex;
   flex-direction: column;
}
.flex{
   display: flex;
}
.flex-col{
   flex-direction: column;
}
.font-bold{
   font-weight: 900;
}
.margin-l-4{
   margin-left: 10px !important;
}
.justify-between{
   justify-content: space-between;
}
.items-center{
   align-items: center;
}
.justify-center{
   justify-content: center;
}