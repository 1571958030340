body {
   margin: 0;
   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
   width: 0.5rem;
   height: 0.5rem;
}

::-webkit-scrollbar-thumb {
   background: #8b909e;
   border-radius: 10px;
}

::-webkit-scrollbar-track {
   background: #b8c0c8;
   border-radius: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
}

.selectt {
   width: 500;
}

.dd {
   width: 70px;
}

.pin {
   width: 30px;
   height: 30px;
   border-radius: 50% 50% 50% 0;
   background: #ea4335;
   position: absolute;
   -webkit-transform: rotate(-45deg);
   -moz-transform: rotate(-45deg);
   -o-transform: rotate(-45deg);
   -ms-transform: rotate(-45deg);
   transform: rotate(-45deg);
   left: 50%;
   top: 50%;
   margin: -20px 0 0 -20px;
   -webkit-animation-name: bounce;
   -moz-animation-name: bounce;
   -o-animation-name: bounce;
   -ms-animation-name: bounce;
   animation-name: bounce;
   -webkit-animation-fill-mode: both;
   -moz-animation-fill-mode: both;
   -o-animation-fill-mode: both;
   -ms-animation-fill-mode: both;
   animation-fill-mode: both;
   -webkit-animation-duration: 1s;
   -moz-animation-duration: 1s;
   -o-animation-duration: 1s;
   -ms-animation-duration: 1s;
   animation-duration: 1s;
}
.poppins{
   font-family: Poppins, sans-serif;
}
.fz-16{
   font-size: 16px;
}
.pin:after {
   content: "";
   width: 14px;
   height: 14px;
   margin: 8px 0 0 8px;
   background: #811411;
   position: absolute;
   border-radius: 50%;
}
.profile-pin {
   width: 38px;
   height: 38px;
   border-radius: 50% 50% 50% 0;
   background: #5c3eba;
   box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.16);
   position: absolute;
   -webkit-transform: rotate(-45deg);
   -moz-transform: rotate(-45deg);
   -o-transform: rotate(-45deg);
   -ms-transform: rotate(-45deg);
   transform: rotate(-45deg);
   left: 50%;
   top: 50%;
   margin: -20px 0 0 -20px;
   /* -webkit-animation-name: bounce;
  -moz-animation-name: bounce;
  -o-animation-name: bounce;
  -ms-animation-name: bounce;
  animation-name: bounce;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -o-animation-duration: 1s;
  -ms-animation-duration: 1s;
  animation-duration: 1s; */
}
.pin-image {
   transform: rotate(45deg);
   position: absolute;
   right: 7px;
   top: 6px;
}
.pulse {
   background: rgba(0, 0, 0, 0.2);
   border-radius: 50%;
   height: 14px;
   width: 14px;
   position: absolute;
   left: 50%;
   top: 50%;
   margin: 11px 0px 0px -12px;
   -webkit-transform: rotateX(55deg);
   -moz-transform: rotateX(55deg);
   -o-transform: rotateX(55deg);
   -ms-transform: rotateX(55deg);
   transform: rotateX(55deg);
   z-index: -2;
}
.pulse:after {
   content: "";
   border-radius: 50%;
   height: 40px;
   width: 40px;
   position: absolute;
   margin: -13px 0 0 -13px;
   -webkit-animation: pulsate 1s ease-out;
   -moz-animation: pulsate 1s ease-out;
   -o-animation: pulsate 1s ease-out;
   -ms-animation: pulsate 1s ease-out;
   animation: pulsate 1s ease-out;
   -webkit-animation-iteration-count: infinite;
   -moz-animation-iteration-count: infinite;
   -o-animation-iteration-count: infinite;
   -ms-animation-iteration-count: infinite;
   animation-iteration-count: infinite;
   opacity: 0;
   -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
   filter: alpha(opacity=0);
   -webkit-box-shadow: 0 0 1px 2px #b15555;
   box-shadow: 0 0 1px 2px #b15555;
   -webkit-animation-delay: 1.1s;
   -moz-animation-delay: 1.1s;
   -o-animation-delay: 1.1s;
   -ms-animation-delay: 1.1s;
   animation-delay: 1.1s;
}
@-moz-keyframes pulsate {
   0% {
      -webkit-transform: scale(0.1, 0.1);
      -moz-transform: scale(0.1, 0.1);
      -o-transform: scale(0.1, 0.1);
      -ms-transform: scale(0.1, 0.1);
      transform: scale(0.1, 0.1);
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
   }
   50% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
   }
   100% {
      -webkit-transform: scale(1.2, 1.2);
      -moz-transform: scale(1.2, 1.2);
      -o-transform: scale(1.2, 1.2);
      -ms-transform: scale(1.2, 1.2);
      transform: scale(1.2, 1.2);
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
   }
}
@-webkit-keyframes pulsate {
   0% {
      -webkit-transform: scale(0.1, 0.1);
      -moz-transform: scale(0.1, 0.1);
      -o-transform: scale(0.1, 0.1);
      -ms-transform: scale(0.1, 0.1);
      transform: scale(0.1, 0.1);
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
   }
   50% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
   }
   100% {
      -webkit-transform: scale(1.2, 1.2);
      -moz-transform: scale(1.2, 1.2);
      -o-transform: scale(1.2, 1.2);
      -ms-transform: scale(1.2, 1.2);
      transform: scale(1.2, 1.2);
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
   }
}
@-o-keyframes pulsate {
   0% {
      -webkit-transform: scale(0.1, 0.1);
      -moz-transform: scale(0.1, 0.1);
      -o-transform: scale(0.1, 0.1);
      -ms-transform: scale(0.1, 0.1);
      transform: scale(0.1, 0.1);
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
   }
   50% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
   }
   100% {
      -webkit-transform: scale(1.2, 1.2);
      -moz-transform: scale(1.2, 1.2);
      -o-transform: scale(1.2, 1.2);
      -ms-transform: scale(1.2, 1.2);
      transform: scale(1.2, 1.2);
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
   }
}
@keyframes pulsate {
   0% {
      -webkit-transform: scale(0.1, 0.1);
      -moz-transform: scale(0.1, 0.1);
      -o-transform: scale(0.1, 0.1);
      -ms-transform: scale(0.1, 0.1);
      transform: scale(0.1, 0.1);
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
   }
   50% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
   }
   100% {
      -webkit-transform: scale(1.2, 1.2);
      -moz-transform: scale(1.2, 1.2);
      -o-transform: scale(1.2, 1.2);
      -ms-transform: scale(1.2, 1.2);
      transform: scale(1.2, 1.2);
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
   }
}
@-moz-keyframes bounce {
   0% {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -webkit-transform: translateY(-2000px) rotate(-45deg);
      -moz-transform: translateY(-2000px) rotate(-45deg);
      -o-transform: translateY(-2000px) rotate(-45deg);
      -ms-transform: translateY(-2000px) rotate(-45deg);
      transform: translateY(-2000px) rotate(-45deg);
   }
   60% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
      -webkit-transform: translateY(30px) rotate(-45deg);
      -moz-transform: translateY(30px) rotate(-45deg);
      -o-transform: translateY(30px) rotate(-45deg);
      -ms-transform: translateY(30px) rotate(-45deg);
      transform: translateY(30px) rotate(-45deg);
   }
   80% {
      -webkit-transform: translateY(-10px) rotate(-45deg);
      -moz-transform: translateY(-10px) rotate(-45deg);
      -o-transform: translateY(-10px) rotate(-45deg);
      -ms-transform: translateY(-10px) rotate(-45deg);
      transform: translateY(-10px) rotate(-45deg);
   }
   100% {
      -webkit-transform: translateY(0) rotate(-45deg);
      -moz-transform: translateY(0) rotate(-45deg);
      -o-transform: translateY(0) rotate(-45deg);
      -ms-transform: translateY(0) rotate(-45deg);
      transform: translateY(0) rotate(-45deg);
   }
}
@-webkit-keyframes bounce {
   0% {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -webkit-transform: translateY(-2000px) rotate(-45deg);
      -moz-transform: translateY(-2000px) rotate(-45deg);
      -o-transform: translateY(-2000px) rotate(-45deg);
      -ms-transform: translateY(-2000px) rotate(-45deg);
      transform: translateY(-2000px) rotate(-45deg);
   }
   60% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
      -webkit-transform: translateY(30px) rotate(-45deg);
      -moz-transform: translateY(30px) rotate(-45deg);
      -o-transform: translateY(30px) rotate(-45deg);
      -ms-transform: translateY(30px) rotate(-45deg);
      transform: translateY(30px) rotate(-45deg);
   }
   80% {
      -webkit-transform: translateY(-10px) rotate(-45deg);
      -moz-transform: translateY(-10px) rotate(-45deg);
      -o-transform: translateY(-10px) rotate(-45deg);
      -ms-transform: translateY(-10px) rotate(-45deg);
      transform: translateY(-10px) rotate(-45deg);
   }
   100% {
      -webkit-transform: translateY(0) rotate(-45deg);
      -moz-transform: translateY(0) rotate(-45deg);
      -o-transform: translateY(0) rotate(-45deg);
      -ms-transform: translateY(0) rotate(-45deg);
      transform: translateY(0) rotate(-45deg);
   }
}
@-o-keyframes bounce {
   0% {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -webkit-transform: translateY(-2000px) rotate(-45deg);
      -moz-transform: translateY(-2000px) rotate(-45deg);
      -o-transform: translateY(-2000px) rotate(-45deg);
      -ms-transform: translateY(-2000px) rotate(-45deg);
      transform: translateY(-2000px) rotate(-45deg);
   }
   60% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
      -webkit-transform: translateY(30px) rotate(-45deg);
      -moz-transform: translateY(30px) rotate(-45deg);
      -o-transform: translateY(30px) rotate(-45deg);
      -ms-transform: translateY(30px) rotate(-45deg);
      transform: translateY(30px) rotate(-45deg);
   }
   80% {
      -webkit-transform: translateY(-10px) rotate(-45deg);
      -moz-transform: translateY(-10px) rotate(-45deg);
      -o-transform: translateY(-10px) rotate(-45deg);
      -ms-transform: translateY(-10px) rotate(-45deg);
      transform: translateY(-10px) rotate(-45deg);
   }
   100% {
      -webkit-transform: translateY(0) rotate(-45deg);
      -moz-transform: translateY(0) rotate(-45deg);
      -o-transform: translateY(0) rotate(-45deg);
      -ms-transform: translateY(0) rotate(-45deg);
      transform: translateY(0) rotate(-45deg);
   }
}
@keyframes bounce {
   0% {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -webkit-transform: translateY(-2000px) rotate(-45deg);
      -moz-transform: translateY(-2000px) rotate(-45deg);
      -o-transform: translateY(-2000px) rotate(-45deg);
      -ms-transform: translateY(-2000px) rotate(-45deg);
      transform: translateY(-2000px) rotate(-45deg);
   }
   60% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
      -webkit-transform: translateY(30px) rotate(-45deg);
      -moz-transform: translateY(30px) rotate(-45deg);
      -o-transform: translateY(30px) rotate(-45deg);
      -ms-transform: translateY(30px) rotate(-45deg);
      transform: translateY(30px) rotate(-45deg);
   }
   80% {
      -webkit-transform: translateY(-10px) rotate(-45deg);
      -moz-transform: translateY(-10px) rotate(-45deg);
      -o-transform: translateY(-10px) rotate(-45deg);
      -ms-transform: translateY(-10px) rotate(-45deg);
      transform: translateY(-10px) rotate(-45deg);
   }
   100% {
      -webkit-transform: translateY(0) rotate(-45deg);
      -moz-transform: translateY(0) rotate(-45deg);
      -o-transform: translateY(0) rotate(-45deg);
      -ms-transform: translateY(0) rotate(-45deg);
      transform: translateY(0) rotate(-45deg);
   }
}

.pin-truck {
   left: 50%;
   top: 50%;
   width: 30px;
   height: 30px;
   position: absolute;
   margin: -8px 0 0 -18px;
}

.pin-text {
   position: absolute;
   font-weight: 600;
   margin: -8px 0 0 -18px;
   margin: -21px 0 0 -25px;
}

/* Pagination */

.page-item:first-child .page-link {
   margin-left: 0;
   border-top-left-radius: 0.25rem;
   border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
   border-top-right-radius: 0.25rem;
   border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
   z-index: 3;
   color: #fff;
   background-color: #5c3eba;
   border-color: #5c3eba;
}

.page-item.disabled .page-link {
   color: #6c757d;
   pointer-events: none;
   cursor: auto;
   background-color: #fff;
   border-color: #dee2e6;
}

.pagination-lg .page-link {
   padding: 0.75rem 1.5rem;
   font-size: 1.25rem;
   line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
   border-top-left-radius: 0.3rem;
   border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
   border-top-right-radius: 0.3rem;
   border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
   padding: 0.25rem 0.5rem;
   font-size: 0.875rem;
   line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
   border-top-left-radius: 0.2rem;
   border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
   border-top-right-radius: 0.2rem;
   border-bottom-right-radius: 0.2rem;
}
.pagination .page-link-item {
   border-radius: 5px;
}
.pagination .prev-link-pagination {
   border-radius: 5px;
   margin-right: 5px;
   color: rgba(0, 0, 0, 0.6);
}
.pagination {
   display: inline-block;
   justify-content: end;
   padding-left: 0;
   margin: 1rem 0;
   padding: 7px;
   border-radius: 4px;
   font-size: 0.8rem;
}
.pagination > ul {
   background-color: #f0f0f0;
}
.pagination > li {
   display: inline;
   margin-right: 10px;
}
.pagination .page-link-item {
   border-radius: 5px;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
   z-index: 3;
   color: #fff;
   cursor: default;
   background-color: #fa354c;
   border-color: #fa354c;
}
.pagination > .disabled > a {
   cursor: default;
   pointer-events: none;
   opacity: 0.5;
   background-color: #f0f0f0;
   border-color: transparent;
   border: 1px solid #f0f0f0;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
   margin-left: 0;
}
.pagination > li > a,
.pagination > li > span {
   position: relative;
   float: left;
   padding: 10px 16px;
   margin-left: -1px;
   line-height: 1.42857143;
   color: #fa354c;
   text-decoration: none;
   border: 1px solid #f0f0f0;
   border-radius: 5px;
}
.pagination > li > a:hover,
.pagination > li > span:hover {
   color: #fa354c;
}
.pagination .next,
.previous {
   display: none !important;
}
.active {
   color: #fff;
}
.owl-prev,
.owl-next {
   display: inline-flex;
   -webkit-box-align: center;
   align-items: center;
   -webkit-box-pack: center;
   justify-content: center;
   min-width: 24px;
   min-height: 24px;
}

.custom-rb[type="radio"]:checked,
[type="radio"]:not(:checked) {
   position: absolute;
   left: -9999px;
}
.custom-rb[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
   position: relative;
   padding-left: 28px;
   cursor: pointer;
   line-height: 20px;
   display: inline-block;
   color: #666;
}
.custom-rb[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
   content: "";
   position: absolute;
   left: 0;
   top: 0;
   width: 18px;
   height: 18px;
   border: 1px solid #ddd;
   border-radius: 100%;
   background: #fff;
}
.custom-rb[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
   content: "";
   width: 10px;
   height: 10px;
   background: #ffd10c;
   position: absolute;
   top: 4px;
   left: 4px;
   border-radius: 100%;
   transition: all 0.2s ease;
}
.custom-rb[type="radio"]:not(:checked) + label:after {
   opacity: 0;
   -webkit-transform: scale(0);
   transform: scale(0);
}
.custom-rb[type="radio"]:checked + label:after {
   opacity: 1;
   -webkit-transform: scale(1);
   transform: scale(1);
}

.custom-rb-purple[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
   background: #5c3eba;
   border-color: #5c3eba;
}
.custom-rb-purple[type="radio"]:checked {
   border-color: #5c3eba;
}
.custom-rb-purple[type="radio"]:checked + label:before {
   border: 2px solid #5c3eba;
}
.custom-rb-purple[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
   width: 8px;
   height: 8px;
   top: 5px;
   left: 5px;
}
.Toastify__toast-container--top-center {
   top: 50% !important;
   left: 50% !important;
   transform: translate(-50%, -50%) !important;
}
